import { get } from 'lodash'
import { useMedia } from 'react-use'
import { useSnackbar } from 'notistack'
import { object, string, boolean } from 'yup'
import React, { useState, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { VisibilityOff, Visibility } from '@material-ui/icons'
import { Formik, Form, FormikProps, Field, FieldProps, FormikHelpers } from 'formik'
import { Button, IconButton, InputAdornment, Dialog, Typography, Checkbox } from '@material-ui/core'

import * as loco from '@loco'
import Close from '../../shared/Close'
import { Routes } from '@variables'
import InputField from '../../Fields/Input'
import { TermsLink } from '../../shared/styled'
import { translateGQLError } from '@utils'
import { AuthContext } from '../../../context/Auth'
import { DIALOG_ID, DialogContext } from '../../../context/Dialog'
import {
    Role,
    MeDocument,
    ChaptersDocument,
    useValidatorsQuery,
    useAcceptInvitationMutation
} from '@graphql'
import {
    Check,
    Title,
    Wrapper,
    Subtitle,
    LoginLink,
    ButtonWrapper,
    CheckboxesWrapper
} from './styled'

type FormValues = {
    password: string
    checkbox: boolean
    passwordAgain: string
}

const InviteDialog = () => {
    const { enqueueSnackbar } = useSnackbar()
    const isWide = useMedia('(min-width: 854px)')

    const history = useHistory()
    const location = useLocation()
    const token = location.search.split('=')[1]

    const { updateUser } = useContext(AuthContext)

    const { setOpen, id } = useContext(DialogContext)
    const isOpen = id === DIALOG_ID.INVITE

    const [showPassword, setShowPassword] = useState(false)
    const toggleShowPwd = () => setShowPassword(!showPassword)

    const { data } = useValidatorsQuery({ skip: !isOpen, onError: (err) => console.log(err) })

    const [acceptInvite, { loading }] = useAcceptInvitationMutation()

    const close = () => setOpen(DIALOG_ID.NOT_DISPLAYED)

    const submit = async (
        { password, passwordAgain }: FormValues,
        { resetForm }: FormikHelpers<FormValues>
    ) => {
        const { data, errors } = await acceptInvite({
            refetchQueries: [{ query: MeDocument }, { query: ChaptersDocument }],
            variables: {
                data: {
                    token: token,
                    password: password,
                    passwordAgain: passwordAgain
                }
            }
        })

        if (data && !errors) {
            updateUser({
                role: data.acceptInvitation.role,
                email: data.acceptInvitation.email,
                username: data.acceptInvitation.username
            })

            if (
                data.acceptInvitation.role === Role.SUPER_ADMIN ||
                data.acceptInvitation.role === Role.ADMIN
            ) {
                history.push({ pathname: Routes.DASHBOARD, search: '' })
            } else {
                history.replace({ pathname: Routes.HOME, search: '' })
            }

            resetForm()
            setOpen(DIALOG_ID.NOT_DISPLAYED)
        }

        if (errors) {
            errors?.forEach((err) =>
                enqueueSnackbar(translateGQLError(err.message), { variant: 'error' })
            )
        }
    }

    const validationSchema = object({
        password: string()
            .required(loco.validation.password.common)
            .matches(
                new RegExp(
                    `${get(
                        data,
                        'validators.password.regex',
                        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$'
                    )}`
                ),
                get(data, 'validators.password.description', loco.validation.password.match)
            ),
        passwordAgain: string()
            .required(loco.validation.password.common)
            .matches(
                new RegExp(
                    `${get(
                        data,
                        'validators.password.regex',
                        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$'
                    )}`
                ),
                get(data, 'validators.password.description', loco.validation.password.match)
            ),
        // terms and conditions
        checkbox: boolean().oneOf([true], loco.validation.password.terms.common)
    })

    return (
        <Dialog
            open={isOpen}
            onClose={close}
            fullScreen={!isWide}
            id={DIALOG_ID.INVITE}
            className="customDialog"
            aria-labelledby={DIALOG_ID.INVITE}
        >
            <Wrapper>
                <Close onClick={close} />

                <Title variant="h3">{loco.test.summary['finish-profile']}</Title>
                <Subtitle variant="body2" align="center">
                    {loco.dialogs.invite.subtitle}
                </Subtitle>

                <Formik
                    validateOnChange
                    onSubmit={submit}
                    isInitialValid={false}
                    initialTouched={{ password: true }}
                    validationSchema={validationSchema}
                    initialValues={{ password: '', passwordAgain: '', checkbox: false }}
                >
                    {(props: FormikProps<FormValues>) => {
                        const { isValid, isSubmitting } = props

                        return (
                            <Form style={{ width: '100%' }} spellCheck={false}>
                                <Field
                                    fullWidth
                                    autoFocus
                                    key="password"
                                    name="password"
                                    component={InputField}
                                    className="customInput"
                                    disabled={isSubmitting || loading}
                                    label={loco.dialogs['new-password'].password.label}
                                    placeholder={loco.dialogs['new-password'].password.placeholder}
                                    // HIDE/SHOW PROPS
                                    type={showPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    edge="end"
                                                    onClick={toggleShowPwd}
                                                    style={{ margin: '0 0 20px 0' }}
                                                    aria-label="toggle password visibility"
                                                >
                                                    {showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <Field
                                    fullWidth
                                    key="passwordAgain"
                                    name="passwordAgain"
                                    component={InputField}
                                    className="customInput"
                                    disabled={isSubmitting || loading}
                                    label={loco.dialogs['new-password']['new-password'].label}
                                    placeholder={
                                        loco.dialogs['new-password']['new-password'].placeholder
                                    }
                                    // HIDE/SHOW PROPS
                                    type={showPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    edge="end"
                                                    onClick={toggleShowPwd}
                                                    style={{ margin: '0 0 20px 0' }}
                                                    aria-label="toggle password visibility"
                                                >
                                                    {showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />

                                <CheckboxesWrapper>
                                    <Check>
                                        <Field required key="checkbox" name="checkbox">
                                            {({ field }: FieldProps) => (
                                                <Checkbox
                                                    {...field}
                                                    disabled={isSubmitting || loading}
                                                />
                                            )}
                                        </Field>
                                        <Typography variant="body2">
                                            {loco.dialogs.signup.termsAgree}
                                        </Typography>
                                        <TermsLink
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={`https://${process.env.RAZZLE_HOST}${Routes.TERMS}`}
                                        >
                                            <Typography variant="body2">
                                                {loco.dialogs.signup.terms}
                                            </Typography>
                                        </TermsLink>
                                    </Check>
                                </CheckboxesWrapper>

                                <ButtonWrapper>
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        disabled={!isValid || isSubmitting || loading}
                                    >
                                        {loco.dialogs.invite.title}
                                    </Button>
                                </ButtonWrapper>

                                <Typography paragraph align="center" style={{ marginBottom: 35 }}>
                                    {loco.dialogs.signup.existing}
                                    <LoginLink onClick={() => setOpen(DIALOG_ID.LOGIN)}>
                                        <b>{loco.dialogs.signup.login}</b>
                                    </LoginLink>
                                </Typography>
                            </Form>
                        )
                    }}
                </Formik>
            </Wrapper>
        </Dialog>
    )
}

export default InviteDialog
